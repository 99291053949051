import fileTree from "../../data/file-tree";

export default function () {
  const result = {
    0: [15, 16, 17, 18],
  };

  for (let item of result[0]) {
    const children = fileTree.filter((el) => el.parentId == item);
    result[item] = {
      ...fileTree.find((el) => el.id == item),
      children: children.map((el) => el.id),
    };

    for (let child of children) {
      result[child.id] = child;
    }
  }

  return result;
}
